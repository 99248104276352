export const constants = {
    networkError: 'Please check your network connection and try again.',
    serverError: 'We are having a problem processing your request. Please try again later.'
}
export const policyError = {
    INVALID_MEMBER: "We could not find this policy. Please check the information entered and try again.",
    MEMBER_TERMINATED: "You are no longer an active member of this policy and cannot submit claims.",
    POLICY_TERMINATED: "This policy has been terminated or has lapsed and claims can no longer be submitted.",
    FUTURE_EFFECTIVE: "Your coverage does not start until",
    General: "Something went wrong. Please try again later.",
    NON_ADULT: "Only members 18 years old or older are allowed to use this portal to submit claims.",
}