/**
* Author: ShepHertz
* Import components and inbuilt modules
*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule , HttpClient } from '@angular/common/http';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { PolicyValidatorComponent } from './policy-validator/policy-validator.component';
import { ClaimProcessComponent } from './claim-process/claim-process.component';
import { SelectFromDiagFilterPipe } from './pipes/searchDiagnosis.pipe';
import { ClaimSubmitComponent } from './claim-submit/claim-submit.component';
import { FooterComponent } from './footer/footer.component';
import { ClaimWarningComponent } from './claim-warning/claim-warning.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ClaimingComponent } from './claim-process/claiming/claiming.component';
import { ClaimTypeComponent } from './claim-process/claim-type/claim-type.component';
import { ConsultationDateComponent } from './claim-process/consultation-date/consultation-date.component';
import { DoctorClinicComponent } from './claim-process/doctor-clinic/doctor-clinic.component';
import { DiagnosisComponent } from './claim-process/diagnosis/diagnosis.component';
import { RecieptAmountComponent } from './claim-process/reciept-amount/reciept-amount.component';
import { OtherClaimedComponent } from './claim-process/other-claimed/other-claimed.component';
import { AttachDocumentComponent } from './claim-process/attach-document/attach-document.component';
import { ReviewClaimComponent } from './claim-process/review-claim/review-claim.component';
import {TranslateModule , TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { TrustedurlPipe } from './trustedurl.pipe';
import { AutofocusModule } from 'angular-autofocus-fix';
import { registerLocaleData } from '@angular/common';
import localeCh from '@angular/common/locales/zh-Hans';
import { AlertComponent } from './alert/alert.component';
import { SelectFromListFilterPipe } from './pipes/searchDoc.pipe';
import { OpenAppComponent } from './open-app/open-app.component';
import { NewHeaderComponent } from './new-header/new-header.component';
import { LoaderComponent } from './loader/loader.component';
import { NgxImageCompressService } from 'ngx-image-compress';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeCh, 'zh-Hans');


@NgModule({
  declarations: [
    SelectFromListFilterPipe,
    AppComponent,
    HeaderComponent,
    PolicyValidatorComponent,
    ClaimProcessComponent,
    SelectFromDiagFilterPipe,
    ClaimSubmitComponent,
    FooterComponent,
    ClaimWarningComponent,
    TermsConditionComponent,
    ClaimingComponent,
    ClaimTypeComponent,
    ConsultationDateComponent,
    DoctorClinicComponent,
    DiagnosisComponent,
    RecieptAmountComponent,
    OtherClaimedComponent,
    AttachDocumentComponent,
    ReviewClaimComponent,
    TrustedurlPipe,
    AlertComponent,
    OpenAppComponent,
    NewHeaderComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    DatePickerModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxQRCodeModule,
    AutofocusModule
  ],
  providers: [ NgxImageCompressService ],
  bootstrap: [AppComponent]
})
export class AppModule {}
