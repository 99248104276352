// import { Component, OnInit } from '@angular/core';
// import { NavigationEnd, Router } from '@angular/router';
// import { FeatureToggleService, IMTranslateService, ProductService } from '@core/services';
// import { Toggles } from '@shared/constants';
// import { ReferenceData } from '@shared/constants';
// import { Language } from '@shared/enums/language';
// import { Modal } from '@shared/models/modal';
// import { RouteUtils } from '@shared/utils';
// import { LanguageSwitchUtils } from '@shared/utils/language-switch';
// import { NgxSmartModalService } from 'ngx-smart-modal';
import { Component, OnInit} from '@angular/core';
import { ScreensService } from '../screens.service';
import { environment } from '../../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.css']
})
export class NewHeaderComponent implements OnInit {
  urls = environment;
  selLang = 'English';
  isNav = true;

  currentModal = { title: 'The title', subtitle: 'subtitle', content: 'content' };
  urlTranslationKey: string;
  isLanguageSwitchDisabled: boolean;
  isAppName: boolean;
  languageMenuLabel: string;
  
  products: { label: string; url: string }[];

  
  

  constructor(
    private _screen: ScreensService,
    private router: Router,
    private translate: TranslateService
  ) { }
  lang: string;
  isDisable = true;

  ngOnInit() {
    const lang = this._screen.getLanguage();
    
    if (lang && lang != 'null') {
      if (lang == 'zh-Hans') {
        this.selLang = "繁體中文";
      }
      this.lang = lang;
    }
    let allowedUrl = ['/', '/?lang=en', '/?lang=tc'];
    this.router.events.subscribe(
      (res: any) => {
        if (res instanceof NavigationEnd) {
          if (allowedUrl.indexOf(res.url) !== -1) {
            if (res.url === '/?lang=en') {
              this.selLang = 'English';
              this._screen.setLanguage('en');
              this.translate.use('en');
            } else if(res.url === '/?lang=tc') {
              this.selLang = "繁體中文";
              this._screen.setLanguage('zh-Hans');
              this.translate.use('zh-Hans');
            }
            this.isDisable = false;
          } else {
            this.isDisable = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );

  
  
  }


  onSelect(select: any, isSmallScreen = false) {
    if (isSmallScreen) {
      this.isNav = !this.isNav;
    }
    if(select.innerText == "English"){
      this.router.navigateByUrl('/?lang=en');
      this.selLang = "English";
      this._screen.selectedLanguage.next("ENGLISH");
    } else {
      this.router.navigateByUrl('/?lang=tc');
      this._screen.selectedLanguage.next('CHINESE');
      this.selLang = "繁體中文";
    }
   
  }

  navigateToFaq() {
    window.open('/faq');
  }

}

