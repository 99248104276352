/**
* Author: ShepHertz
* Entry point of Application
*/
import { Component, OnInit} from '@angular/core';
import { fadeAnimation } from './animations';
import { ScreensService } from './screens.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import { loadCldr } from '@syncfusion/ej2-base';
import { PolicyService } from './policy.service';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var require: any;

loadCldr(
  require('cldr-data/main/zh-Hans/numbers.json'),
  require('cldr-data/main/zh-Hans/ca-gregorian.json'),
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/zh-Hans/timeZoneNames.json'),
  require('cldr-data/supplemental/weekData.json') // To load the culture based first day of week
);


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation] // register the animation
})
export class AppComponent  implements OnInit {
  title = 'Liberty EasyClaim';
  constructor(private _screen: ScreensService,
    private translate: TranslateService,
    private titleService: Title,
    ) {
    }
    ngOnInit() {
      this.appendGaTrackingCode();
      const lang = this._screen.getLanguage();
      if (lang == null || lang ==  "null") {
        this.translate.setDefaultLang('en');
      } else {
        this.translate.setDefaultLang(lang);
      }
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('Liberty EasyClaim').subscribe((res: string) => {
          this.titleService.setTitle(res);
        });
      });
      this._screen.selectedLanguage
        .subscribe(
          (data: string) => {
            if (data === 'CHINESE') {
              this._screen.setLanguage('zh-Hans');
              // this.policyService.checkResponseInLocaStorage('zh-Hans');
              this.translate.use('zh-Hans');
            } else if (data === 'ENGLISH') {
              this._screen.setLanguage('en');
              this.translate.use('en');
              // this.policyService.checkResponseInLocaStorage('en');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }

    private appendGaTrackingCode() {
      try {
        const script1 = document.createElement('script');
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
        script1.setAttribute('async','');
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '` + environment.googleAnalyticsKey + `');
        `;
        let head = document.getElementsByTagName('head')[0];
        head.appendChild(script1);
        head.appendChild(script);
        
      } catch (ex) {
       console.error('Error appending google analytics');
       console.error(ex);
      }
    }
}
