/**
* Author: ShepHertz
* ClaimProcessComponent form
*/
import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';

import {  IInsuredMember, Claim } from '../models/models';
import { ScreensService } from '../screens.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PolicyService } from '../policy.service';
import { SaveFileService } from '../save-file.service';

@Component({
  selector: 'app-claim-process',
  templateUrl: './claim-process.component.html',
  styleUrls: ['./claim-process.component.css'],
  animations: [
    trigger('slideInOut', [
      transition('* => claiming,* => claimType, * => consultDate, * => doctorClinic,  * => diagnosis, * => receiptAmount, * => otherClaim, * => attachDocument', [
        style({ opacity: 0 }), animate('0.6s')
      ])
    ])
  ]
})

export class ClaimProcessComponent implements OnInit {
  insuranceMember: IInsuredMember;
  isShowDiagnosisError = false;
  memberClaim: Claim;
  myConsultDate: string;
  doctorName: string;
  diagnosis;
  mycurrency;
  otherClaim;
  myProgressbar;
  files;
  docNames;
  loading = false;
  locale: string;
  membersArray: any[];
  message: string;
  isShowPopup = false;
constructor(
  private _screens: ScreensService,
  private  _router: Router,
  private translate: TranslateService,
  private policyService: PolicyService,
  private saveFile: SaveFileService
     ) {
  }
ngOnInit() {
  this.membersArray = this.policyService.getInsuredMembers();
  let lang = this._screens.getLanguage();
  if (lang == null || lang == "null") {
    lang = "en";
  }
  this.locale = lang;
  this.translate.onLangChange.subscribe(
    (data: LangChangeEvent) => {
      this.locale = data.lang;
    },
    (err) => {
      console.log(err);
    }
  );
  this.insuranceMember = this._screens.getInsuredMember();
  this._screens.insuranceMember
  .subscribe(
    (data: IInsuredMember) => {
      this.insuranceMember = data;
    },
    (err) => {
      console.log(err);
    }
   );

    // getting claimimg member
    this.memberClaim = this._screens.getClaim();
    this._screens.memberClaim
      .subscribe(
        (data: Claim) => {
          this.memberClaim = data;
        },
        (err) => {
          console.log(err);
        }
      );

    // GETING CONSULT DATE

    this.myConsultDate = this._screens.getConsultDate();
    if (this.myConsultDate && this.myConsultDate.charAt(0) === '"' && this.myConsultDate.charAt(this.myConsultDate.charAt.length - 1) === '"') {
      this.myConsultDate = JSON.parse(this.myConsultDate);
    }
    this._screens.consultDate.subscribe(
      (date: string) => {
        this.myConsultDate = date;
      },
      (err) => {
        console.log(err);
      }
    );
    // getting doctorname
    this.doctorName = this._screens.getDoctorName();
    this._screens.doctorName.subscribe(
      (data: string) => {
        this.doctorName = data.trim();
      },
      (err) => {
        console.log(err);
      }
    );

    // getting diagnosis
    this.diagnosis = this._screens.getDiagnosis();
    this._screens.diagnosis.subscribe(
      (data: any) => {
        this.isShowDiagnosisError = false;
        this.diagnosis = data;
      },
      (err) => {
        console.log(err);
      }
    );
  // getting currency

  this.mycurrency = this._screens.getCurrency();
  this._screens.currency.subscribe(
    (data: any) => {
      this.mycurrency = data;
    },
    (err) => {
      console.log(err);
    }
  );

  // getting other claim
  this.otherClaim = this._screens.getOtherClaim();
  this._screens.othercliam.subscribe(
    (data: any) => {
      this.otherClaim = data;
    },
    (err) => {
      console.log(err);
    }
  );

  this.docNames = this._screens.getDocNames();
  this._screens.docNames.subscribe(
    (res: any) => {
      this.docNames = res;
    },
    (err) => {
      console.log(err);
    }
  );

  // getting attach document
  this.files = this.saveFile.getFiles();
  console.log(this.files);
  this.saveFile.files.subscribe(
    (res: any) => {
      this.files = res;
      console.log(this.files);
    },
    (err) => {
      console.log(err);
    }
  );
  // progress bar
  this._screens.progressbar.subscribe(
    (data: any) => {
      this.myProgressbar = data;
    },
    (err) => {
      console.log(err);
    }
  );
   // while user change the diffrent claim type and diagnosis not belong to the selected claim type
   this.isShowDiagnosisError = this._screens.getDiagnosisError();
  this._screens.diagnosisError.subscribe(
    (res: boolean) => {
      this.isShowDiagnosisError = res;
    },
    (err) => {
      console.log(err);
    }
  );
}

  /**
   * Sets latest screen
   * @param url URL
   */
  setLatestScreen(url: string, isInsurer = false, isDiagnosis = false) {
    if(isInsurer && this.membersArray.length === 1) {
      this.isShowPopup = true;
      this.message = 'You cannot edit this item as there is only one insured member.';
      return;
    }
    if(isDiagnosis && this.memberClaim && this.memberClaim.diagnosisTypes.length === 1 && !this.isShowDiagnosisError) {
      this.isShowPopup = true;
      this.message = 'You cannot edit this item as the selected claim type only allows for one diagnosis type.';
      return;
    }
    this._screens.setLatestScreen(this._router.url);
    if (url.indexOf('#') > -1) {
      const fragment = url.split('#');
      const query = fragment[0].split("?");
      this._router.navigate([query[0]],{fragment: fragment[1], queryParams: {screen: query[1] }});
    } else {
      this._router.navigate([url]);
    }
  }
  prepareOutlet(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData['animation'];
  }

  removeRegex(str: string) {
    let fr = str.match(/ \(If applicable\)| \(如適用\)/);

    if (fr !== null) { 
      let l = str.substr(0, fr.index);
      return l;
    }
    return str;
  }
}
