export const environment = {
  production: true,
  baseUrl         : 'http://34.73.3.99/1.0',
  claimApiUrl     : 'https://claim.lhc.libertymutual.com.hk',
  policyApiUrl    : 'https://policy.lhc.libertymutual.com.hk',
  toeknApiUrl     : 'https://authen.lhc.libertymutual.com.hk',
  currencyApi     : 'https://master.lhc.libertymutual.com.hk',
  termsUrl        : 'https://www.libertyinsurance.com.hk/en/terms-and-conditions/',
  privacyUrl      : 'https://www.libertyinsurance.com.hk/en/privacy-policy/',
  mobilePrivacyUrl: 'https://www.libertyinsurance.com.hk/en/mobile-app-policy/',
  easyClaimUrl    : 'https://www.libertyinsurance.com.hk/',
  contactUsUrl    : 'https://www.libertyinsurance.com.hk/LHC/contactUs.jsp?lang=0',
  appPlayStoreUrl : 'https://play.google.com/store/apps/details?id=com.libertyhk.eClaim&hl=en',
  appAppleUrl     : 'https://apps.apple.com/hk/app/libertyhk-liberty-health-club/id1066669506?l=en',
  pdfFormUrl      : 'https://www.libertyinsurance.com.hk/download/claim_outpatient.pdf',
  terms_and_conditionUrl : 'https://ww02.libertymutual.com.hk/Pages/ECSD_0001.htm?v=20200520',
  acceptableUrl:"https://www.libertyinsurance.com.hk/acceptable-use-policy",
  cookieUrl: 'https://libertyinsurance.com.hk/cookie-policy',
  endUserUrl : 'https://libertyinsurance.com.hk/end-user-application-policy',
  personalUrl : 'https://libertyinsurance.com.hk/personal-information-collection-statement',
  premiumUrl : 'https://libertyinsurance.com.hk/premium-levy',
  privacyLinkUrl: 'https://libertyinsurance.com.hk/privacy-policy',
  securityUrl:'https://libertyinsurance.com.hk/security-policy',
  tncUrl:'https://libertyinsurance.com.hk/terms-and-conditions',
  googleAnalyticsKey: 'UA-145594648-3'

};
