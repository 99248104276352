/**
* Author: ShepHertz
* HeaderComponent
* Visible in top section of all pages
*/
import { Component, OnInit} from '@angular/core';
import { ScreensService } from '../screens.service';
import { environment } from '../../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  urls = environment;
  selLang = 'ENGLISH'; 
  constructor(private _screen: ScreensService,private router: Router
    ) { }
    lang: string;
    isDisable = true;
  ngOnInit() {
    const lang = this._screen.getLanguage();
    if (lang && lang != 'null') {
      this.lang = lang;
      if (this.lang == 'zh-Hans') {
        this.selLang = 'CHINESE'; 
      }
    } else {
      this.lang = 'en';
    }
    this.router.events.subscribe(
      (res: any) => {
        if (res instanceof NavigationEnd) {
          if (res.url == "/") {
            this.isDisable = false;
          } else {
            this.isDisable = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onSelect(select: any) {
    this._screen.selectedLanguage.next(select.value);
  }
}
