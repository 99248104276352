  import { Component, OnInit } from '@angular/core';
import { ScreensService } from 'src/app/screens.service';
import { Router } from '@angular/router';
import { PolicyService } from 'src/app/policy.service';

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css']
})
export class DiagnosisComponent implements OnInit {

  diagnosisNameArray: any[];
  dSearchStr = '';
  docSelected: boolean;
  diagnosisNameSearchArray;
  interval: any;
  isShowPopup = false;

  constructor(private _screens: ScreensService,
     private _router: Router,
     private policyService:PolicyService) { }
  ngOnInit() {
    this.interval = setInterval( () => {
      if(this.policyService.isTokenExpire()) {
        localStorage.removeItem('token');
        this.isShowPopup = true;
        clearInterval(this.interval);
      }
    }, 1000);
    const json = {
      description: 'Other - 其他',
      code: '998',
      diagnosisChineseDescription: 'other'
    };
    this._screens.calcProgressBarPercent(5);
    const dignos = this._screens.getDiagnosis();
    const claim = this._screens.getClaim();
    this._screens.memberClaim.subscribe(
      (res:any) => {
        this.diagnosisNameArray = res.diagnosisTypes;
      },
      (err) => {
        console.log(err);
      }
    );
   
    if  ( claim != null) {
      this.diagnosisNameArray = Object.assign([], claim.diagnosisTypes);
     
      this.diagnosisNameSearchArray = this.diagnosisNameArray;
    }
    if (dignos != null) {
      this.dSearchStr = dignos.description;
    }
    //if diagnosis is invalid set to empty
    let dignosError = this._screens.getDiagnosisError();
    if (dignosError != null) {
      if (dignosError) {
        this.dSearchStr = '';
      }
    }
  }
   /**
   * Shows diagnosis list
   */
  showDiagList() {
    this.docSelected = false;

    if (this.dSearchStr.length > 0) {
    }
  }


  /**
   * Selected diagnosis
   * @param diagnosis stor to localstorage
   */
  selectedDiagnosis(diagnosis: any) {
    this._screens.setDiagnosis(diagnosis);
    this._screens.setDiagnosisError(false);
    const latestSCreen = this._screens.getLatestScreen();
    const prevRoute = ['/claim-process', '/claim-process/claim-type', '/claim-process/consult-date', '/claim-process/doctor-clinic' ];
    if ((latestSCreen != null) && (latestSCreen !== '/claim-process/diagnosis') && (prevRoute.indexOf(latestSCreen) === -1)) {
      this._router.navigateByUrl(latestSCreen);
    } else {
      this._router.navigate(['/claim-process/receipt-amount']);
    }
  }
  onClose() {
    this.isShowPopup = false;
    this._router.navigate(['/']);
  }
}
