
/**
* Author: ShepHertz
* reviewClaimComponent
* Showed to the user after attach reciept
*/
import { Component, OnInit } from '@angular/core';
import { IInsuredMember, Claim } from 'src/app/models/models';
import { ScreensService } from 'src/app/screens.service';
import { PolicyService } from 'src/app/policy.service';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { constants } from 'src/app/constants';
import { SaveFileService } from 'src/app/save-file.service';
@Component({
  selector: 'app-review-claim',
  templateUrl: './review-claim.component.html',
  styleUrls: ['./review-claim.component.css']
})
export class ReviewClaimComponent implements OnInit {
  insuranceMember: IInsuredMember;
  isDiagnosisError: boolean;
  memberClaim: Claim;
  myConsultDate: string;
  doctorName: string;
  docNames;
  diagnosis;
  mycurrency;
  otherClaim;
  fileObj;
  termsCondition = false;
  locale: string;
  interval: any;
  isShowPopup = false;
  files:any[];
  docId: string;
  loading = false;
  membersArray: any[];
  isDisable = false;
  serverError: string;
  isShowDignosInsuredPopup = false;
  message: string;
  constructor(
    private _screens: ScreensService,
    public policyService: PolicyService,
    private translate: TranslateService,
    private saveFile: SaveFileService,
    private router: Router) { }
    
    ngOnInit() {
      this.interval = setInterval( () => {
        if(this.policyService.isTokenExpire()) {
          localStorage.removeItem('token');
          this.isShowPopup = true;
          clearInterval(this.interval);
        }
    }, 1000);
    // this.policyService.getDocId().subscribe(
      //   (res: any) => {
        //     console.log(res);
        //     this.docId = res.docId;
        //     console.log(this.docId);
        //   }, 
        //   (err) => {
    //     console.log(err);
    //   }
    // );
    let lang = this._screens.getLanguage();
    if (lang == null || lang == "null") {
      lang = 'en';
    }
    this.locale = lang;
  
    this.translate.onLangChange.subscribe(
      (data: LangChangeEvent) => {
        this.locale = data.lang;
      }
      );
      let memberArry = this.policyService.getInsuredMembers();
    this.membersArray = memberArry;
    
    this.insuranceMember = this._screens.getInsuredMember();
    if ( this.insuranceMember == null) {
      return this.router.navigate(['/']);
    }
    this.memberClaim = this._screens.getClaim();
    this.myConsultDate = this._screens.getConsultDate();
    if (this.myConsultDate && this.myConsultDate.charAt(0) === '"' && this.myConsultDate.charAt(this.myConsultDate.charAt.length - 1) === '"') {
      this.myConsultDate = JSON.parse(this.myConsultDate);
    }
    this.doctorName = this._screens.getDoctorName();
    this.diagnosis = this._screens.getDiagnosis();
    
    this.otherClaim = this._screens.getOtherClaim();
    this.mycurrency = this._screens.getCurrency();
    this.fileObj = this.saveFile.getFiles();
    console.log(this.fileObj);
      // get docNames
    this.docNames = this._screens.getDocNames();
    this.isDiagnosisError = this._screens.getDiagnosisError();
    // getting file array
    this.files = this._screens.getFilesLength(this.fileObj);
   
    if (this.fileObj === undefined || (Object.keys(this.fileObj).length === 0 || this.fileObj['Receipt'].length === 0)) {
      this.isDisable= true;
    }
    if (this.isDiagnosisError) {
      this.isDisable= true;
    }
    
  }

  
  /**
   * Submits form
   */
  async submitForm() {
    this.loading = true;
    // prepare data for submission;
    const policy =  this.policyService.getPolicy();
    const policyArr = policy.policyNumber.split('-');

    //format the date
    let format = function date2str(x, y) {
      var z = {
          M: x.getMonth() + 1,
          d: x.getDate(),
          h: x.getHours(),
          m: x.getMinutes(),
          s: x.getSeconds()
      };
      y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
          return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
      });
  
      return y.replace(/(y+)/g, function(v) {
          return x.getFullYear().toString().slice(-v.length)
      });
    }



    const myDate = format(new Date(this.myConsultDate), 'yyyy-MM-dd');
    const data = {}
    
    data['numberOfPhoto'] = this.files.length;
    data['doctorName'] = this.doctorName;
    data['otherDiagnosis'] = '';
    data['otherPaidAmount'] = '00.00'; // set 00.00
    data['otherPaidCurrency'] = 'HKD'; // set recipt selected currency
    data['patientName'] =  this.insuranceMember.mmrlnm;
    data['policyNumber'] = policyArr[0].toUpperCase();
    data['policySubCode'] = policyArr.pop();
    data['receiptAmount'] = this.mycurrency.price;
    data['receiptCurrency'] = this.mycurrency.currency;
    data['submissionType'] = 'EC';
    data['transactionStatus'] = '';
    data['certificateNumber'] = this.insuranceMember.certNo.toUpperCase();
    data['claimCategoryCode'] = '';
    data['claimType'] = this.memberClaim.type;
    data['consulationDate'] = myDate;
    data['diagnosisCode'] = this.diagnosis.code;
    data['willSubmitOtherInsurer'] = 'N';
    // data['docId'] = this.docId;

    let json    = this.mycurrency;
    let price   = parseInt(json.price, 10); // old price
    price       = await this.convertCurrency(price, json.currency);
    if (this.otherClaim.price !== 'None') {
      json          = this.otherClaim; // new currency json
      data['otherPaidAmount'] = json.price;
      data['otherPaidCurrency'] = json.currency;
      data['willSubmitOtherInsurer'] = 'Y';
      let newPrice  = parseInt(json.price, 10);
      newPrice      = await this.convertCurrency(newPrice, json.currency);
      price         = price - newPrice; // if new price
    } 
    const myFileObjArr = [];
    for( let file of this.files) {
      if(file == null){
      }
      else {
        const myFileObj  =  this._screens.urlToFile(file.imgUrl, file.name,file.type);
        myFileObjArr.push(myFileObj);
      }

    }
    this.policyService.submitClaim(data, myFileObjArr).subscribe(
      (res: any) => {
        this.loading = false;
        localStorage.setItem('refrenceCode',res.refCode);
        if (res.rejectMsg == "3") {
          this.router.navigate(['/claim-warning']);
        } else {
          this.router.navigate(['/claim-submit']);
        }
      },
      (err) => {
        this.loading = false;
        if (!navigator.onLine) {
          this.serverError = constants.networkError;
        } else {
          this.serverError = err.error.message;
        }
        window.scrollTo(0,0);
        console.log(err);
      }
    );
  }

  /**
   * Selects latest screen when user edit
   * @param url string
   */
  selectLatest(url: string, isInsurer = false, isDiagnosis = false) {
    if(isInsurer && this.membersArray.length === 1) {
      this.isShowDignosInsuredPopup = true;
      this.message = 'You cannot edit this item as there is only one insured member.';
      return;
    }
    if(isDiagnosis && this.memberClaim && this.memberClaim.diagnosisTypes.length === 1 && !this.isDiagnosisError) {
      this.isShowDignosInsuredPopup = true;
      this.message = 'You cannot edit this item as the selected claim type only allows for one diagnosis type.';
      return;
    }
    this._screens.setLatestScreen(this.router.url);
    if (url.indexOf('#') > -1) {
      const fragment = url.split('#');
      const query = fragment[0].split("?");
      this.router.navigate([query[0]],{fragment: fragment[1], queryParams: {screen: query[1] }});
    } else {
      this.router.navigate([url]);
    }
  }

  /**
   * Converts currency
   */
 async  convertCurrency(price: number, currencyCode: string) {
    if (price < 0) {
      return 0;
    }
    if (currencyCode === 'HKD') {
      return price;
    }
    const convert = await this.policyService.convertCurrency(price, currencyCode);
    return convert.amount;
  }
  onClose() {
    this.isShowPopup = false;
    this.router.navigate(['/']);
  }

  removeRegex(str: string) {
    let fr = str.match(/ \(If applicable\)| \(如適用\)/);

    if (fr !== null) { 
      let l = str.substr(0, fr.index);
      return l;
    }
    return str;
  }
}
