/**
* Author: ShepHertz
* footerComponent
* Visible in bottom section of all pages
*/
import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  urls = environment;
  constructor() { }

  ngOnInit() {
  }
  getCurrentYear() {
    return new Date().getFullYear();
  }
}
