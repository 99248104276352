/**
* Author: ShepHertz
* Search pipe for doctor search
*/
import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '@syncfusion/ej2-splitbuttons';

@Pipe({
    name: 'selectDoctorFilter'
})
export class SelectFromListFilterPipe implements PipeTransform {
    transform(availableItemsList: any[], filterString: string) {
        const filteredList = availableItemsList.filter(item => {
            if (item.name.toLowerCase().includes(filterString.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        });
        if (filteredList.length === 0) {
            const json = {
                name: filterString,
                id: 1
            };
            filteredList.push(json);
        }
        return filteredList;
    }
}
