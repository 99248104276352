import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaveFileService {
  totalFiles: any[];
  public files = new Subject();
  constructor() { }


  saveFiles(filesobj) {
    this.totalFiles = filesobj;
    this.files.next(filesobj);
  }

  /**
   * Gets files
   * @returns  filesarray
   */
  getFiles() {
    return this.totalFiles;
  }

  // reset files
  reset() {
    this.totalFiles = undefined;
  }
}
