/**
* Author: ShepHertz
* claimWarningComponent
* Showed to user when claim amount is greater than 2000
*/
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ScreensService } from '../screens.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claim-warning',
  templateUrl: './claim-warning.component.html',
  styleUrls: ['./claim-warning.component.css']
})
export class ClaimWarningComponent implements OnInit, OnDestroy {
  buttonText = 'Copy Reference Code';
  count = 1;
  urls = environment;
  refrenceCode: string;
  url: string;
  constructor(private _rendrer: Renderer2, private _screens: ScreensService,private _router: Router) { }

  ngOnInit() {
    window.addEventListener('scroll',this.DoScroll,true);
    
    // set the qr code
    this.url = window.location.origin + '/open-app';
    this._rendrer.setStyle(document.body, 'backgroundColor', '#fff');
    this.refrenceCode = localStorage.getItem('refrenceCode');
    if (this.refrenceCode == null) {
      this._router.navigate(['/']);
    }
    this._screens.removeClaim();
    this._screens.removeConsultDate();
    this._screens.removeCurrency();
    this._screens.removeDiagnosis();
    this._screens.removeDoctor();
    this._screens.removeMember();
    this._screens.removeFiles();
    this._screens.removeOtherClaim();
    this._screens.removeLatest();
  }
  /* To copy reference text */
  copyReferenceCode(val: string) {
    this.count++;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.buttonText = 'Copied!';
    if (this.count > 2) {
      this.buttonText = 'Copied again!';
    }
  }

  


 DoScroll = (e):void => {
    const mydiv = document.getElementById('ybox');
    if (pageYOffset > 162) {
      mydiv.style.top = 24 + 'px';
    } else {
      mydiv.style.top = 162 + 'px';
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.DoScroll,true);
  }
}
