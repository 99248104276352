import { ClaimingComponent } from './claim-process/claiming/claiming.component';
/**
* Author: ShepHertz
* Routing of components
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PolicyValidatorComponent } from './policy-validator/policy-validator.component';
import { ClaimProcessComponent } from './claim-process/claim-process.component';
import { ClaimSubmitComponent } from './claim-submit/claim-submit.component';
import { ClaimWarningComponent } from './claim-warning/claim-warning.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ClaimTypeComponent } from './claim-process/claim-type/claim-type.component';
import { ConsultationDateComponent } from './claim-process/consultation-date/consultation-date.component';
import { DoctorClinicComponent } from './claim-process/doctor-clinic/doctor-clinic.component';
import { DiagnosisComponent } from './claim-process/diagnosis/diagnosis.component';
import { RecieptAmountComponent } from './claim-process/reciept-amount/reciept-amount.component';
import { OtherClaimedComponent } from './claim-process/other-claimed/other-claimed.component';
import { AttachDocumentComponent } from './claim-process/attach-document/attach-document.component';
import { ReviewClaimComponent } from './claim-process/review-claim/review-claim.component';
import { OpenAppComponent } from './open-app/open-app.component';

const routes: Routes = [
  {path: '', component: PolicyValidatorComponent},
  {
    path: 'claim-process',
    component: ClaimProcessComponent,
    children: [
      {
        path: '',
        component: ClaimingComponent,
        data: {animation: 'claiming'}
      },
      {
        path: 'claim-type',
        component: ClaimTypeComponent,
        data: {animation: 'claimType'}
      },
      {
        path: 'consult-date',
        component: ConsultationDateComponent,
        data: {animation: 'consultDate'}
      },
      {
        path: 'doctor-clinic',
        component: DoctorClinicComponent,
        data: {animation: 'doctorClinic'}
      },
      {
        path: 'diagnosis',
        component: DiagnosisComponent,
        data: {animation: 'diagnosis'}
      },
      {
        path: 'receipt-amount',
        component: RecieptAmountComponent,
        data: {animation: 'receiptAmount'}
      },
      {
        path: 'other-claimed',
        component: OtherClaimedComponent,
        data: {animation: 'otherClaim'}
      },
      {
        path: 'attach-document',
        component: AttachDocumentComponent,
        data: {animation: 'attachDocument'}
      }
    ]
  },
  {
    path: 'review-claim',
    component: ReviewClaimComponent
  },
  {path: 'claim-submit', component: ClaimSubmitComponent},
  {path: 'claim-warning', component: ClaimWarningComponent},
  {path: 'terms-condition', component: TermsConditionComponent},
  {path: 'open-app', component: OpenAppComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
