/**
* Author: ShepHertz
* ClaimSubmitComponent
* Showed to the user on completion of Claim process
*/
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ScreensService } from '../screens.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claim-submit',
  templateUrl: './claim-submit.component.html',
  styleUrls: ['./claim-submit.component.css']
})
export class ClaimSubmitComponent implements OnInit, OnDestroy {
  buttonText = 'Copy Reference Code';
  count = 0;
  urls = environment;
  refrenceCode: string;
  url: string;
  constructor(private _rendrer: Renderer2, private _screens: ScreensService,private _router:Router) { }

  ngOnInit() {
    // set the qr code
    this.url = window.location.origin + '/open-app';
    this._rendrer.setStyle(document.body, 'backgroundColor', '#fff');
    // empty the localstorage while submit data
    this.refrenceCode = localStorage.getItem('refrenceCode');
    if (this.refrenceCode == null) {
      this._router.navigate(['/']);
    }
    this._screens.removeClaim();
    this._screens.removeConsultDate();
    this._screens.removeCurrency();
    this._screens.removeDiagnosis();
    this._screens.removeDoctor();
    this._screens.removeMember();
    this._screens.removeFiles();
    this._screens.removeOtherClaim();
    this._screens.removeLatest();
  }

  /* To copy reference text */
  copyReferenceCode(val: string) {
    this.count++;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.buttonText = 'Copied!';
    if (this.count > 1) {
      this.buttonText = 'Copied again!';
    }
  }
  ngOnDestroy() {
    this._rendrer.removeStyle(document.body, 'backgroundColor')
  }

}
