import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'src/app/policy.service';
import { ScreensService } from 'src/app/screens.service';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SaveFileService } from 'src/app/save-file.service';

@Component({
  selector: 'app-attach-document',
  templateUrl: './attach-document.component.html',
  styleUrls: ['./attach-document.component.css'],
  animations: [
    trigger('slidInOut', [
      transition('* <=> *', [style({ opacity: 0 }), animate('0.6s')])
    ])
  ]
})
export class AttachDocumentComponent implements OnInit {
  state = '1';
  fileAttached = false;
  fileShowError = false;
  refWarning = false;
  files: any = [];
  showfileError = false;
  fileSizeError = false;
  isShowDropText = false;
  fileSizeMessage = '';
  attachedFileError = 'You cannot upload more than 5 images.';
  fileTypeError = 'Only files with the following extensions are allowed: JPEG & JPG.';
  ngxQrcode = 'http://' + window.location.host + '/claim-process/attach-document';
  isShowPopup = false;
  qrModal = false;
  interval: any;
  docTypes: any[];
  screens = 8;
  activatedScreen: string;
  totalFiles: any = {};
  constructor(
    private policyService: PolicyService,
    private _screens: ScreensService,
    private saveFile: SaveFileService,
    private _router: Router,
    private _activated: ActivatedRoute,
    private imageCompress: NgxImageCompressService
  ) { }
  ngOnInit() {

    if (this.saveFile.getFiles() === undefined) {
      this._router.navigate(['/claim-process/attach-document']);
    }
    this.docTypes = this._screens.getClaim().docTypes;
    if (this.docTypes.length === 0) {
      // this.docTypes.push({
      //   docCode: 'Receipt',
      //   mandatory: true
      // });
      this._router.navigate(['/review-claim']);
    }
    this.docTypes.forEach((doc) => {
      this.totalFiles[doc.docCode] = [];
    });
    // prevent to show the next attach screen without getting it
    if (this.docTypes.length > 1) {
      for (let i = 1; i < this.docTypes.length; i++) {
        this.totalFiles[this.docTypes[i].docCode] = null;
      }
    }
    this.interval = setInterval(() => {
      if (this.policyService.isTokenExpire()) {
        localStorage.removeItem('token');
        this.isShowPopup = true;
        clearInterval(this.interval);
      }
    }, 1000);
    this._activated.fragment.subscribe(
      (res: any) => {
        this.counter = 0;
        window.scrollTo(0, 0);
        this.showfileError = false;
        this.fileSizeError = false;
        this.fileShowError = false;
        if (this.state == '1') {
          this.state = '2';
        } else {
          this.state = '1';
        }
        const screenNumber = this._activated.snapshot.queryParams['screen'];
        let screen;
        if (screenNumber) {
          screen = this.screens + (screenNumber * (0.92 / this.docTypes.length));
        } else {
          screen = this.screens + (0.92 / this.docTypes.length);
        }
        this._screens.calcProgressBarPercent(screen);
        this.activatedScreen = res;

        if (this.activatedScreen == null) {
          this.activatedScreen = this.docTypes[0].docCode;
        }
        const files = this.saveFile.getFiles();
        if (files) {
          this.totalFiles = files;
          if (this.totalFiles[this.activatedScreen] == null) {
            this.totalFiles[this.activatedScreen] = [];
          }
          if (this.totalFiles[this.activatedScreen].length === 0) {
            this.fileAttached = false;
          } else {
            this.fileAttached = true;
          }
        } else {
          this.fileAttached = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );

  }

  /**
   * Determines whether change on
   * @param uploadFile in file object
   * @noreturns
   */
  async  onChange(uploadFile, code) {
    const files = uploadFile.files;
    this.fileAttached = true;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== 'image/jpeg' &&
        files[i].type !== 'image/jpg') {
        this.fileShowError = true;
        if (this.totalFiles[this.activatedScreen].length === 0) {
          this.fileAttached = false;
        }
        return;
      }
      if ((files[i].size / (1024 * 1024)) <= 5) {
        this.fileSizeError = false;
        this.fileAttached = true;
        if ((this._screens.getFilesLength(this.totalFiles).length + files.length) <= 5) {
          this.showfileError = false;
          this.fileSizeError = false;
          const reader = new FileReader();
          reader.onload = async () => {
            if ((files[i].size / (1024 * 1024)) > 1.8) {
              const result = await this.recursiveCompress(reader.result);
              this.totalFiles[code].push({
                imgUrl: result,
                name: this.changeExtension(files[i].name),
                size: files[i].size,
                type: 'image/jpg',
              });
              if (i === files.length - 1) {
                this.savefileDataIntoStorage(this.totalFiles, code);
                uploadFile.value = '';
              }
            } else {
              this.totalFiles[code].push({
                imgUrl: reader.result,
                name: this.changeExtension(files[i].name),
                size: files[i].size,
                type: 'image/jpg',
              });
              if (i === files.length - 1) {
                this.savefileDataIntoStorage(this.totalFiles, code);
                uploadFile.value = '';
              }
            }
          };
          reader.readAsDataURL(files[i]);
        } else {
          this.showfileError = true;
          return;
        }
        this.fileShowError = false;
      } else {
        this.fileSizeError = true;
        if (this.totalFiles[this.activatedScreen].length === 0) {
          this.fileAttached = false;
        }
        this.fileSizeMessage = 'Size of each file must be less than or equal to 5 MB.';
      }
    }
  }

  recursiveCompress = async (result: any) => {
    const mbs = (result.length * 6 / 8) / (1024 * 1024);
    if (mbs <= 1.8 ) {
      return result;
    } else {
      return this.recursiveCompress(await this.imageCompress.compressFile(result, -1, 65, 100));
    }
  }

  /**
   * Savefiles data into localstorage
   * @param filesArray  filearray
   */
  savefileDataIntoStorage(fileobj, code?: string) {
    this.saveFile.saveFiles(fileobj);
  }

  /**
   * Deletes file
   * @param index file array index
   */
  deleteFile(index, code) {
    this.totalFiles[code].splice(index, 1);
    if (this.totalFiles[code].length === 0) {
      this.fileAttached = false;
    }
    this.savefileDataIntoStorage(this.totalFiles);
  }

  /**
   * Reviews claim
   */

  reviewClaim(index) {
    const url = this._screens.getLatestScreen();
    const prevRoute = ['/claim-process',
      '/claim-process/claim-type',
      '/claim-process/consult-date',
      '/claim-process/doctor-clinic',
      '/claim-process/diagnosis',
      '/claim-process/receipt-amount',
      '/claim-process/other-claimed'];
    if ((url != null) && (url.indexOf('/claim-process/attach-document') == -1) && (prevRoute.indexOf(url) === -1)) {
      if (this.totalFiles[this.activatedScreen].length === 0 && !this.docTypes[index].mandatory && this.docTypes[index].msg) {
        this.refWarning = true; return;
      }
      this._router.navigateByUrl(url);
    } else {
      if (this.docTypes.length - 1 == index) {
        if (this.totalFiles[this.activatedScreen].length === 0 && !this.docTypes[index].mandatory && this.docTypes[index].msg) {
          this.refWarning = true; return;
        }
        this._router.navigate(['/review-claim']);
      } else {
        this.state = '2';
        this._router.navigate(['/claim-process/attach-document'],
          { fragment: this.docTypes[index + 1].docCode, queryParams: { screen: index + 2 } });
      }
    }
  }
  /**
   * Drops down attach file
   * @param ev files
   */
  drop(ev, code) {
    ev.preventDefault();
    const files = [];
    this.isShowDropText = true;
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          files.push(file);
        }
      }
    } else {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];
        files.push(file);
      }
    }

    const fileObj = { files };
    this.onChange(fileObj, code);
    this.isShowDropText = false;
  }
  allowDrop(event: any) {
    event.preventDefault();
  }
  counter = 0;
  onDragEnter(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.isShowDropText = true;
    this.counter++;
  }
  onleave() {
    this.counter--;
    if (this.counter === 0) {
      this.isShowDropText = false;
    }
  }
  onClose() {
    this.isShowPopup = false;
    this._router.navigate(['/']);
  }
  modal() {
    this.qrModal = !this.qrModal;
  }
  changeExtension(name: string): string {
    if (name.split('.').pop().toLowerCase() === 'jpg') {
      return name;
    } else {
      const arr = name.split('.');
      arr.pop();
      arr.push('jpg');
      return arr.join('.');
    }
  }
}
