import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


/**
 * Injectable
 */
/**
 * Injectable
 */
@Injectable({
  providedIn: 'root'
})
export class ScreensService {
  public insuranceMember = new Subject();
  public memberClaim = new Subject();
  public consultDate = new Subject();
  public doctorName = new Subject();
  public diagnosis = new Subject();
  public currency = new Subject();
  public othercliam = new Subject();
  public progressbar = new Subject();
  public selectedLanguage = new Subject();
  public files = new Subject();
  public docNames = new Subject();
  public diagnosisError = new Subject();
  totalScreen = 9;
  constructor(

  ) { }
  // get file length for testing length
  getFilesLength(filesObj): any[] {
    let arr = [];
    for (const i in filesObj) {
      if (filesObj[i]) {
        arr = arr.concat(filesObj[i]);
      }
    }
    return arr;
  }
  /**
   * Sets insured member
   */
  setInsuredMember(data: any) {
    if (typeof data === 'string') {
      localStorage.setItem('insuredMember', data);
      this.insuranceMember.next(JSON.parse(data));
    } else {
      localStorage.setItem('insuredMember', JSON.stringify(data));
      this.insuranceMember.next(data);
    }
  }

  /**
   * Gets insured member
   * @returns  insured member
   */
  getInsuredMember() {
    return JSON.parse(localStorage.getItem('insuredMember'));
  }

  /**
   * Removes member
   */
  removeMember() {
    localStorage.removeItem('insuredMember');
  }

  /**
   * Sets claim type
   */
  setClaimType(member: any) {
    if (typeof member === 'string') {
      localStorage.setItem('claimType', member);
      this.memberClaim.next(JSON.parse(member));
    } else {
      localStorage.setItem('claimType', JSON.stringify(member));
      this.memberClaim.next(member);
    }
  }

  setDocNames(docNamesJson) {
    localStorage.setItem('docNames', JSON.stringify(docNamesJson));
    this.docNames.next(docNamesJson);
  }

  getDocNames(): {receipt: string, referral: string} {
    const j = localStorage.getItem('docNames');
    if (j === null) {
      return {receipt: '', referral: ''};
    } else {
      return JSON.parse(j);
    }
  }

  /**
   * Gets claim
   * @returns  cliam
   */
  getClaim() {
    return JSON.parse(localStorage.getItem('claimType'));
  }

  /**
   * Removes claim
   */
  removeClaim() {
    localStorage.removeItem('claimType');
  }

  // set diagnosis error
  setDiagnosisError(isSet: boolean) {
    localStorage.setItem('diagnosisError', JSON.stringify(isSet));
    this.diagnosisError.next(isSet);
  }
  // get diagnosis error
  getDiagnosisError() {
    return JSON.parse(localStorage.getItem('diagnosisError'));
  }

  /**
   * Sets consult date
   */
  setConsultDate(date: string) {
      localStorage.setItem('consultDate', JSON.stringify(date));
      this.consultDate.next(date);
  }

  /**
   * Gets consult date
   * @returns  consult date
   */
  getConsultDate() {
    return localStorage.getItem('consultDate');
  }

  /**
   * Removes consult date
   */
  removeConsultDate() {
    localStorage.removeItem('consultDate');
  }

  /**
   * Sets doctor
   */
  setDoctor(name: string) {
    localStorage.setItem('doctorName', name);
    this.doctorName.next(name);
  }

  /**
   * Gets doctor name
   * @returns doctor name
   */
  getDoctorName() {
    return localStorage.getItem('doctorName');
  }

  /**
   * Removes doctor
   */
  removeDoctor() {
    localStorage.removeItem('doctorName');
  }



  /**
   * Sets diagnosis
   */
  setDiagnosis(diagnosis: any) {
    if (typeof diagnosis === 'string') {
      localStorage.setItem('diagnosis', diagnosis);
      this.diagnosis.next(JSON.parse(diagnosis));
    } else {
      localStorage.setItem('diagnosis', JSON.stringify(diagnosis));
      this.diagnosis.next(diagnosis);
    }
  }

  /**
   * Gets diagnosis
   * @returns  diagnosis
   */
  getDiagnosis() {
    return JSON.parse(localStorage.getItem('diagnosis'));
  }

  /**
   * Removes diagnosis
   */
  removeDiagnosis() {
    localStorage.removeItem('diagnosis');
  }


  /**
   * Sets currency
   */
  setCurrency(data: any) {
    if (typeof data === 'string') {
      localStorage.setItem('currency', data);
      this.currency.next(JSON.parse(data));
    } else {
      localStorage.setItem('currency', JSON.stringify(data));
      this.currency.next(data);
    }
  }

  /**
   * Gets currency
   * @returns  currency
   */
  getCurrency() {
    return JSON.parse(localStorage.getItem('currency'));
  }

  /**
   * Removes currency
   */
  removeCurrency() {
    localStorage.removeItem('currency');
  }
  /**
   * Sets other claimed
   */
  setOtherClaimed(data: any) {
    if (typeof data === 'string') {
      localStorage.setItem('otherclaimed', data);
      this.othercliam.next(JSON.parse(data));
    } else {
      localStorage.setItem('otherclaimed', JSON.stringify(data));
      this.othercliam.next(data);
    }
  }

  /**
   * Gets other claim
   */
  getOtherClaim() {
    return JSON.parse(localStorage.getItem('otherclaimed'));
  }

  /**
   * Removes other claim
   */
  removeOtherClaim() {
    localStorage.removeItem('otherclaimed');
  }

  /**
   * Saves files
   */
  saveFiles(filesobj) {
    localStorage.setItem('files', JSON.stringify(filesobj));
    this.files.next(filesobj);
  }

  /**
   * Gets files
   * @returns  filesarray
   */
  getFiles() {
    return JSON.parse(localStorage.getItem('files'));
  }

  /**
   * Removes files
   */
  removeFiles() {
    localStorage.removeItem('files');
  }



  /**
   * Calculates progress bar percent
   */
  calcProgressBarPercent(screen: number) {
    const divider = (screen / this.totalScreen) * 100;
    const percent = Math.floor(divider);
    if (percent >= 100) {
      this.progressbar.next(100);
    } else {
      this.progressbar.next(Math.floor(percent));
    }
  }




  /**
   * Sets url of latest screen
   */
  setLatestScreen(url: string) {
    localStorage.setItem('latestScreen', url);
  }

  /**
   * Gets latest screen
   * @returns  latest screen
   */
  getLatestScreen() {
    return localStorage.getItem('latestScreen');
  }

  /**
   * Removes latest
   */
  removeLatest() {
    localStorage.removeItem('latestScreen');
  }

  /**
   * Sets data for mobile
   */
  setDataForMobile(data: any) {
    localStorage.setItem('mobileData', data);
  }

  /**
   * Gets data for mobile
   * @returns  data for mobile
   */
  getDataForMobile() {
    return localStorage.getItem('mobileData');
  }

  /**
   * Parses string
   * @returns  string
   */
  parseString(data: any) {
    if (typeof data === 'string') {
      return JSON.parse(data);
    } else {
      return data;
    }
  }

  /**
   * Sets language
   * @param lang string
   */
  setLanguage(lang: string) {
    lang = (lang === null || lang === 'null') ? 'en' : lang;
    localStorage.setItem('lang', lang);
  }
  getLanguage() {
    return localStorage.getItem('lang');
  }

  //url to file object
  urlToFile(url: any, fileName: string, type: string) {
    var byteString = atob(url.split(',')[1]);
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    if (!navigator.msSaveBlob) {
      return new File([ab], fileName,{
        type: 'image/jpg',
        lastModified: Date.now(),
      });
    } else {
      let a:any =  new Blob([ab], {type});
      a.lastModifiedDate = new Date();
      a.name = fileName;
      return a;
    }
  }

  
}


