/**
* Author: ShepHertz
* Search pipe for diagonosis search
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'selectDiagFilter'
})
export class SelectFromDiagFilterPipe implements PipeTransform {
    transform(availableItemsList: any[], filterString: string) {
        let filteredList = [];
        if (availableItemsList !== undefined) {
            filteredList = availableItemsList.filter(item => {
                if (item.description.toLowerCase().includes(filterString.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            if (filteredList.length === 0) {
                const otherFound = availableItemsList.find(el => {
                    return (el.code === '998' || el.code === 'D22');
                });
                if (otherFound) {
                    filteredList.push(otherFound);
                 }
            }
        }
        return filteredList;
    }
}
