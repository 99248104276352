import { Component, OnInit } from '@angular/core';
import { ScreensService } from 'src/app/screens.service';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// Load the L10n, loadCldr from ej2-base
import { L10n } from '@syncfusion/ej2-base';
import { PolicyService } from 'src/app/policy.service';

// load the locale object to set the localized placeholder value
L10n.load({
  'zh-Hans': {
    datepicker: {
      today: '今天'
    }
  }
});

@Component({
  selector: 'app-consultation-date',
  templateUrl: './consultation-date.component.html',
  styleUrls: ['./consultation-date.component.css']
})
export class ConsultationDateComponent implements OnInit {
  locale: string;
  isShowPopup = false;
  interval: any;
  constructor(
    private _screens: ScreensService,
    private policyService:PolicyService,
    private translate: TranslateService,
    private _router: Router) { }
    public date;
    public today: Date              = new Date();
    public currentYear: number      = this.today.getFullYear();
    public currentMonth: number     = this.today.getMonth();
    public currentDay: number       = this.today.getDate();
    public minDate: Object          = new Date(this.currentYear, this.currentMonth, this.currentDay - 90);
    public maxDate: Object          = new Date(this.currentYear, this.currentMonth, this.currentDay);
    ngOnInit() {
      this.interval = setInterval( () => {
        if(this.policyService.isTokenExpire()) {
          localStorage.removeItem('token');
          this.isShowPopup = true;
          clearInterval(this.interval);
        }
      }, 1000);
      this._screens.calcProgressBarPercent(3);
      this.date = this._screens.getConsultDate();
      let lang = this._screens.getLanguage();
      if (lang == null || lang == "null") {
        lang = 'en';
      }
      this.locale = lang;
      const policyDetail = this.policyService.getApiResponse(lang + '-policy').policyDetail;
      const date1 = new Date();
      
      //const date2 = new Date(policyDetail.effFrom);
      const date2 = new Date(policyDetail.members[0].inceptionDate);
   
      const diffTime = Math.abs(date2.getTime() - date1.getTime());

      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      if (diffDays < 90) {
        this.minDate = date2;
      }
      
      this.translate.onLangChange.subscribe(
        (data: LangChangeEvent) => {
          this.locale = data.lang;
        },
        (err) => {
          console.log(err);
        }
      );
      if (this.date == null) {
        this.date = new Date();
      } else {
        if (this.date.charAt(0) === '"' && this.date.charAt(this.date.charAt.length - 1) === '"') {
          this.date = JSON.parse(this.date);
        }
      }
  }


  /**
   * detect change in consult date
   */
  consultDate(calenderDate) {
    this.date = calenderDate.value;
  }

  /**
   * Prev date
   * @param calender set to date
   */
  prevDate(calender) {
    const CurrentDate: Date = new Date();
    CurrentDate.setDate(CurrentDate.getDate() - 1);
    calender.value = CurrentDate;
  }

  /**
   * Sets consult date
   */
  setConsultDate() {
    this._screens.setConsultDate(this.date);
    this._router.navigate(['/claim-process/doctor-clinic']);
    const prevRoute = ['/claim-process', '/claim-process/claim-type' ];
    const latestSCreen = this._screens.getLatestScreen();
    if ((latestSCreen != null) && (latestSCreen !== '/claim-process/consult-date') && (prevRoute.indexOf(latestSCreen) === -1)) {
      this._router.navigateByUrl(latestSCreen);
    } else {
      this._router.navigate(['/claim-process/doctor-clinic']);
    }
  }
  onClose() {
    this.isShowPopup = false;
    this._router.navigate(['/']);
  }
}
