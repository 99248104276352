/**
* Author: ShepHertz
* TermsConditionComponent
* showed when user click on terms and condition link in the review claim component
*/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PolicyService } from '../policy.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css']
})
export class TermsConditionComponent implements OnInit {
  @ViewChild('frame') iframe: ElementRef;
  constructor(private policyService: PolicyService) { }
  url = environment.terms_and_conditionUrl;

  
  

  ngOnInit() {
    // setTimeout(() => {
    //   window.top.postMessage(document.body.scrollHeight, 'http://' + window.location.host + '/terms-condition');
    // }, 500);
    // window.addEventListener('message', (e) => {
    //   this.iframe.nativeElement.style.height = e.data + 'px';
    // } , false);
  }
}
