/**
* Author: ShepHertz
* PolicyService for making API calls and storing values in localstorage
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { IPolicyInput, PolicyInput, Policy, ClaimDetail, TokenResponse, Currency } from './models/models';
import { environment } from '../environments/environment';
import { Observable, Subject} from 'rxjs';
import { headerData } from './models/models';
import { ScreensService } from './screens.service';
import 'blueimp-canvas-to-blob';
@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  baseUrl = environment.baseUrl;
  policyApi = environment.policyApiUrl;
  tokenApi = environment.toeknApiUrl;
  claimApi = environment.claimApiUrl;
  currencyApi = environment.currencyApi;
  insuredMembersArray = new Subject();
  memberTypeArray = new Subject();
  constructor(
    private httpClient: HttpClient,
    private screeService: ScreensService
  ) {  }
  /**
   * Gets policy details
   * @param policy IPolicyInput
   * @returns Policy
   */
  async getPolicyDetails(policy: IPolicyInput) {
    const dashboardUrl = this.policyApi + '/api/policy/get/detail';
    let language = this.screeService.getLanguage();
    language = this.getLangCodeForApi(language);
    const body = new PolicyInput(policy.policyNumber, policy.certNo, policy.identityNumber, language);
    this.setPolicy(body);
    let certArr = policy.certNo.split('-');
    let policyArr = policy.policyNumber.split('-');
    let certNo = certArr[0];
    let policyNo = policyArr[0];
    function appendHiphen(input, arr) {
      for(let i = 1; i <= arr.length-2; i++) {
        input += '-' + arr[i];
      }
      return input;
    }
    const tokenBody = {
      ...headerData,
      certNo: appendHiphen(certNo, certArr),
      hkid: policy.identityNumber,
      policyNo: appendHiphen(policyNo, policyArr),
      relCode: certArr.pop(),
      subCode: policyArr.pop()
    };
    let tokenForApi;
    let token = JSON.parse(localStorage.getItem('token'));


    if (!token) {
      const tokenResponse = await this.getAUthToken(tokenBody);
      if(tokenResponse.status){
        localStorage.setItem('token',JSON.stringify(tokenResponse.content));
        tokenForApi = tokenResponse.content.token;
      } else {
        return new Promise(
          (resolve, reject) => {
            resolve({status: 401, message: 'Invalid member',tokenResponse});
          }
        );
      }
    } else {
      tokenForApi = token.token;
    }
    const headers = new HttpHeaders({
      ...headerData,
      'x-auth-token': tokenForApi
    });
   
    return this.httpClient.post<Policy>(dashboardUrl, body, {headers}).toPromise();
  }
  getAUthToken(body:any) {
    const api = this.tokenApi+'/api/token/new';
    return this.httpClient.post<TokenResponse>(api,body).toPromise();
  }

  /**
   * Gets claim details
   * @returns ClaimDetail
   */
  getClaimDetails() {
    const token = JSON.parse(localStorage.getItem('token'));
    const dashboardUrl = this.claimApi + '/api/claim/get/type';
    const headers = new HttpHeaders({
        ...headerData,
        'x-auth-token':token.token
    });
    const body = this.getPolicy();
    let data;
    let language = this.screeService.getLanguage();
    if (!language) {
      language = 'EN';
    }
    language = this.getLangCodeForApi(language);
    if (body != null) {
      data = {
        certNumber: body.certNo,
        langCode: language,
        policyNumber: body.policyNumber
      }
    }
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post<ClaimDetail>(dashboardUrl, data, {headers: headers});
  }

  /**
   * Deletes storage item
   * @param keyname string
   */
  deleteStorageItem(keyname: string) {
    localStorage.removeItem(keyname);
  }

  /**
   * Sets policy
   * @param policyInput Object
   */
  setPolicy(policyInput) {
    localStorage.setItem('policy', JSON.stringify(policyInput));
  }

  /**
   * Gets policy
   * @returns Policy Object
   */
  getPolicy() {
    return JSON.parse(localStorage.getItem('policy'));
  }

  /**
   * Gets insured members
   * @returns insuredMembers Object
   */
  getInsuredMembers() {
    return JSON.parse(localStorage.getItem('insuredMembers'));
  }

  /**
   * Sets insured members
   * @param insuredMembers Object
   */
  setInsuredMembers(insuredMembers) {
    localStorage.setItem('insuredMembers', JSON.stringify(insuredMembers));
    this.insuredMembersArray.next(insuredMembers);
  }
  setItem(key, value) {
    localStorage.setItem(key, value);
  }
  getItem(key) {
    return (localStorage.getItem(key));
  }
  /**
   * Sets screen on local
   */
  setLocalData(showScreen) {
    localStorage.setItem('showScreen', showScreen );
    }

  getLocalData(data) {
    return (localStorage.getItem(data));
  }
  /**
   * Sets selected Answers on local
   */
  setSelectedValue(selectedAnswer) {
    localStorage.setItem('selectedAnswer', JSON.stringify(selectedAnswer));
  }
  /**
   * get selected answers on local
   */
  getSelectedValue(value) {
    return JSON.parse(localStorage.getItem(value));
  }
  /**
   * delete all store value of answers and screen
   */
  deleteSelectedValue() {
    localStorage.removeItem('showScreen');
    localStorage.removeItem('selectedAnswer');
  }
  /**
   * Sets currency handling
   * @param req Object
   */
  getHKDCurrencyRate(req) {
    const response = {
      message: 'success',
      status: '1',
      localAmount: 0.2536
    };
    return response;
  }

  /**
   * Compress image
   * @returns compress
   */
  compress(file: File): Observable<any> {
    const width = 600; // For scaling relative to width
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return Observable.create(observer => {
      reader.onload = ev => {
        const img = new Image();
        img.src = (ev.target as any).result;
        (img.onload = () => {
          const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
          const scaleFactor = width / img.width;
          elem.width = width;
          elem.height = img.height * scaleFactor;
          const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
          ctx.canvas.toBlob(
            blob => {
              const b:any = new Blob([blob], {type: 'image/jpeg'});
              observer.next({b,name:file.name});
            },
            'image/jpeg',
            1
          );
        }),
          (reader.onerror = error => observer.error(error));
      };
    });
  }
  getCorruncyList() {
    let language = this.screeService.getLanguage();
    if (!language) {
      language = 'EN';
    }
    language = this.getLangCodeForApi(language);
    const apiUrl = this.currencyApi + '/api/currency/list?lang=' + language;
    return this.httpClient.get<Currency[]>(apiUrl) ;
  }
  convertCurrency(price: number, code: string) {
    const convertApi = this.currencyApi + '/api/currency/convert?amount=' + price + '&&currencyCode=' + code;
    return this.httpClient.get<any>(convertApi).toPromise();
  }
  isTokenExpire(): boolean {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      const diff = token.expires - Date.now()
      if(diff > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  getDocId() {
    const token = JSON.parse(localStorage.getItem('token'));
    const data = this.getPolicy();
    console.log(data);
    const body = {
      certNumber: data.certNo,
      policyNumber: data.policyNumber
    }
    const headers = new HttpHeaders({
      ...headerData,
      'x-auth-token':token.token
    });
    
    return this.httpClient.post(this.claimApi + '/api/document/get/id', body, {headers});
  }

  // finally submit the claim
  submitClaim(dat, fils) {
    // const data = new FormData();
    const body = new FormData();
    // for(let key in dat) {
    //   files.append(key, dat[key]);
    // }
    body .append('data', JSON.stringify(dat));
    for(let file of fils) {
      body .append('files',file);
    }
    const token = JSON.parse(localStorage.getItem('token'));
    let headers = new HttpHeaders({
      ...headerData,
      'x-auth-token':token.token
    });
    // const body = {data,files};
    return  this.httpClient.post(this.claimApi + '/api/claim/submit', body, {headers});
  }

  // get language code 
  getLangCodeForApi(language: string): string {
    switch (language) {
      case 'en':
        language = 'EN';
        break;
      case 'zh-Hans': 
        language = 'TC';
        break;
    }
    return language;
  }
  // storing the api respose to localstorage with lang code
  setApiResponse(key: string, response: any) {
      localStorage.setItem(key, JSON.stringify(response));
  }
  getApiResponse(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  // manipulate the api respose and render data whenever required while changing the language
  async checkResponseInLocaStorage(language: string) {
    if (this.getPolicy() != null) {
      let policyResponse = this.getApiResponse(language + '-policy');
      let claimsResponse = this.getApiResponse(language + '-claims');
      if (policyResponse) {
        this.renderDataTOView(policyResponse, 'policy');
      } else {
        const policy = this.getPolicy();
        const response = await this.getPolicyDetails(policy)
        this.setApiResponse(language + '-policy', response);
        this.renderDataTOView(response, 'policy');
      }
      if (claimsResponse) {
        this.renderDataTOView(claimsResponse, 'claims');
      } else {
        let response = await this.getClaimDetails().toPromise();
        this.setApiResponse(language + '-claims', response.result);
        this.renderDataTOView(response.result, 'claims');
      }
    }
  }
  renderDataTOView(response: any, apiName: string) {
    console.log(response);
    console.log(apiName);
    // switch(apiName) {
    //   case 'policy':
    //     console.log(response);
    //     this.setInsuredMembers(response.policyDetail.members);
    //     let members = response.policyDetail.members;
    //     const selectedInsuredMember = this.screeService.getInsuredMember();
    //     if (selectedInsuredMember) {
    //       members = members.filter((o) => o.schemecod === selectedInsuredMember.schemecod)
    //       this.screeService.setInsuredMember(members[0]);
    //     }
    //   break;
    //   case 'claims':
    //     this.memberTypeArray.next(response);
    //     localStorage.setItem('claims', JSON.stringify(response));
    //     const selectedClaimType = this.screeService.getClaim();
    //     let selectedDiagnosis;
    //     let diagnosisArr;
    //     if (selectedClaimType) {
    //       selectedDiagnosis = this.screeService.getDiagnosis();
    //       diagnosisArr = selectedClaimType.diagnosisTypes;
    //       response = response.filter( (o) => o.type === selectedClaimType.type);
    //       this.screeService.setClaimType(response[0]);
    //     }
    //     if (selectedDiagnosis) {
    //       diagnosisArr = diagnosisArr.filter((o) => o.code === selectedDiagnosis.code);
    //       this.screeService.setDiagnosis(diagnosisArr[0]);
    //     }
    //   break;
    // }

  }
}

