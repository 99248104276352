import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  constructor() { }
  @Output('close') close = new EventEmitter<any>();
  ngOnInit() {
  }
  closed() {
    this.close.emit();
  }

}
