/**
* Author: ShepHertz
* PolicyValidatorComponent loads form where user can input policy details
*/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { PolicyService } from '../policy.service';
import { IPolicyInput, PolicyInput } from '../models/models';
import { ScreensService } from '../screens.service';
import { constants } from '../constants';
import { policyError } from '../constants';
import { SaveFileService } from '../save-file.service';

@Component({
  selector: 'app-policy-validator',
  templateUrl: './policy-validator.component.html',
  styleUrls: ['./policy-validator.component.css']
})
export class PolicyValidatorComponent implements OnInit, AfterViewInit {
  loading = false;
  errText = '';
  locale: string;
  terminatedDate: string;
  policyForm = new FormGroup({
    policyNumber: new FormControl('', [Validators.required, Validators.pattern(/^[\w\s\u4e00-\u9eff)\(]+$/), Validators.maxLength(8)]),
    policyText: new FormControl('', [Validators.required, Validators.pattern(/^[\w\s\u4e00-\u9eff)\(]+$/), Validators.maxLength(3)]),
    certificateNumber: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    certificateText: new FormControl('', [Validators.required,  Validators.maxLength(2)]),
    passportNumber: new FormControl('', [Validators.required, Validators.pattern(/^[\w\s\u4e00-\u9eff)\(]+$/), Validators.maxLength(10)]),
  });
  policyFormValidationMessages = {
    policyNumber: [
      {type: 'required', message: 'Policy Number is a required field.'},
      {type: 'pattern', message: 'Policy Number cannot contain special characters.'},
      {type: 'maxlength', message: 'Policy number must contain 8 characters and subsidiary code must contain 3 numeric digits.'}
    ],
    certificateNumber: [
      {type: 'required', message: 'Certificate Number is a required field.'},
      {type: 'pattern', message: 'Certificate Number cannot contain special characters.'},
      {type: 'maxlength', message: 'Certificate number can contain upto 10 characters with additional 2 digit code.'}
    ],
    passportNumber: [
      {type: 'required', message: 'HK ID / Passport Number is a required field.'},
      {type: 'pattern', message: 'HK ID / Passport Number cannot contain special characters.'},
      {type: 'maxlength', message: 'HK ID / Passport Number can contain up to 10 characters.'},
    ]
};
  constructor(
    private policyService: PolicyService,
    private router: Router,
    private _screens: ScreensService,
    private saveFileService: SaveFileService
    ) { }

    ngOnInit() {
     
      // delete localstorage
      this._screens.removeClaim();
      this._screens.removeConsultDate();
      this._screens.removeCurrency();
      this._screens.removeDiagnosis();
      this._screens.removeDoctor();
      this._screens.removeMember();
      this._screens.removeFiles();
      this._screens.removeOtherClaim();
      this._screens.removeLatest();
      /* Delete Previous localStorage values if present */
      let lang = this._screens.getLanguage();
      localStorage.clear();
      this._screens.setLanguage(lang);
      let langForDate = this._screens.getLanguage();
      this.saveFileService.reset();
      if (langForDate && langForDate != 'null') {
        this.locale = langForDate;
      } else {
        this.locale = 'en';
      }
      //  tranlate the future effective date
      this._screens.selectedLanguage
      .subscribe(
        (data: string) => {
          if (data === 'CHINESE') {
            this.locale = 'zh-Hans';
          } else {
            this.locale = 'en';
          }
        },
        (err) => {
          console.log(err);
        }
      )
      if (this.policyService.getPolicy()) {
        this.policyService.deleteStorageItem('policy');
      }
      if (this.policyService.getInsuredMembers()) {
        this.policyService.deleteStorageItem('insuredMembers');
      }
    }
    ngAfterViewInit() {
      setTimeout(() => {
          for (const key in this.policyForm.controls) {
              if (this.policyForm.controls[key]) {
                  this.policyForm.controls[key].markAsPristine();
              }
          }
      });
    }

    /* Method to mark all form fields as dirty */
    markFieldsAsDirty() {
      Object.keys(this.policyForm.controls).forEach(field => {
        const control = this.policyForm.get(field);
        control.setValue(control.value.trim().toUpperCase());
        control.markAsDirty({ onlySelf: true }); 
        control.markAsTouched();
      });
    }

    /* Method called on Continue button click */
    onSubmit() {
      /* Mark all form fields as dirty and continue only if form is valid */
      this.markFieldsAsDirty();
      if (this.policyForm.valid) {
        const formJson             = this.policyForm.value;
        formJson.policyNumber      = formJson.policyNumber.trim() + '-' + formJson.policyText.trim();
        formJson.certificateNumber = formJson.certificateNumber + '-' + formJson.certificateText;
        delete formJson.policyText;
        delete formJson.certificateText;
        const inputBody: IPolicyInput = new PolicyInput(formJson.policyNumber, formJson.certificateNumber, formJson.passportNumber,'EN');
        this.loading = true;
        /* Get policy from input form values by calling service method 'getPolicyDetails'*/
        this.policyService.getPolicyDetails(inputBody).then((res: any) => {
          this.loading = false;
          if(res.status != 401){
            this.policyService.setItem('policyNumber', formJson.policyNumber);
            // for storing the policy respose with language in localstorage
            let lang = this._screens.getLanguage()
            if (lang == null || lang == "null") {
              lang = 'en';
            }
            this.policyService.setApiResponse( lang + '-policy', res);

            this.policyService.setInsuredMembers(res.policyDetail.members);
            if (res.policyDetail.members.length == 1) {
              this._screens.setInsuredMember(res.policyDetail.members[0]);
              this.router.navigate(['/claim-process/claim-type']);
            } else {
              this.router.navigate(['/claim-process']);
            }
          } else {
            if (res.tokenResponse) {
              this.terminatedDate = null;
              let content = res.tokenResponse.content;
              let messageExist = ['NON_ADULT', 'INVALID_MEMBER', 'MEMBER_TERMINATED', 'POLICY_TERMINATED', 'FUTURE_EFFECTIVE']
              if (messageExist.indexOf(content.rejectCode) !== -1) {
                this.errText = policyError[content.rejectCode];
                if (content.dates) {
                  this.terminatedDate = content.dates;
                }
              } else {
                this.errText = policyError['General'];
              }
            } 
            else {
              this.errText = policyError['General'];
            }
          } 
        }, err => {
          if (!navigator.onLine) {
            this.errText = constants.networkError;
          } else {
            this.errText = constants.serverError;
          }
          this.loading = false;
          console.log(err);
        });
      }
    }

}
