import { IInsuredMember } from './../../models/models';
import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'src/app/policy.service';
import { ScreensService } from 'src/app/screens.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claiming',
  templateUrl: './claiming.component.html',
  styleUrls: ['./claiming.component.css']
})
export class ClaimingComponent implements OnInit {
  insuredMemberArray: IInsuredMember[];
  insuredMem: IInsuredMember;
  selectedAnswers = [];
  interval: any;
  isShowPopup = false;
  constructor(
    private policyService: PolicyService,
    private screenService: ScreensService,
    private router: Router) { }
  ngOnInit() {
    this.interval = setInterval( () => {
      if(this.policyService.isTokenExpire()) {
        localStorage.removeItem('token');
        this.isShowPopup = true;
        clearInterval(this.interval);
      }
    }, 1000);
    this.insuredMemberArray     = this.policyService.getInsuredMembers();
    this.policyService.insuredMembersArray.subscribe(
      (res: any) => {
        this.insuredMemberArray = res;
      },
      (err) => {
        console.log(err);
      }
    );
    this.screenService.calcProgressBarPercent(1);
    this.insuredMem = this.screenService.getInsuredMember();
    this.policyService.getClaimDetails().subscribe(res => {
      let lang = this.screenService.getLanguage();
      if (lang == null || lang == "null") {
        lang = 'en';
      }
      this.policyService.setApiResponse(lang + '-claims', res.result);
      const claims = Object.assign([], res.result);
      localStorage.setItem('claims', JSON.stringify(claims));
    }, err => {
      console.log(err);
    }, () => {
    });
  }
  /**
   * Selected claim
   * @param insurensedMember store to localstorage
   */
  selectedClaim(insurensedMember: IInsuredMember) {
    this.screenService.setInsuredMember(insurensedMember);
    const latestSCreen = this.screenService.getLatestScreen();
    if ((latestSCreen != null) && (latestSCreen !== '/claim-process')) {
      this.router.navigateByUrl(latestSCreen);
    } else {
      this.router.navigate(['/claim-process/claim-type']);
    }
  }
  onClose() {
    this.isShowPopup = false;
    this.router.navigate(['/']);
  }
}
