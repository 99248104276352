import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'src/app/policy.service';
import { ScreensService } from 'src/app/screens.service';
import { Router } from '@angular/router';
import { Currency } from 'src/app/models/models';

@Component({
selector: 'app-other-claimed',
templateUrl: './other-claimed.component.html',
styleUrls: ['./other-claimed.component.css']
})
export class OtherClaimedComponent implements OnInit {

    claimInsurerSelected: boolean;
    selected: number;
    claimAmountPrice: string;
    currency: string;
    claimError = false;
    claimCurrency: string;
    secondErrorText: string;
    isDisable = true;
    currencyErrorTexts = {
        wrongAmount: 'Please enter the correct amount.',
        errorTextAmount: 'Amount should be less than 9,999,999 and greater than 0',
        claimAmount: 'Amount should be greater than 0 and less than claim amount.',
        decimalAmount: 'Amount should be less than 7 digits before decimal and 2 digits after decimal.',
    };
    currencies: Currency[];
    interval: any;
    isShowPopup = false;
    constructor(
    private policyService: PolicyService,
    private _screens: ScreensService,
    private _router: Router) { }

    ngOnInit() {
        this.interval = setInterval( () => {
            if(this.policyService.isTokenExpire()) {
              localStorage.removeItem('token');
              this.isShowPopup = true;
              clearInterval(this.interval);
            }
          }, 1000);
        this.claimCurrency = 'HKD';
        this.policyService.getCorruncyList().subscribe(
            (resp: Currency[]) => {
            this.currencies = resp;
            },
            (err) => {
            console.log(err);
            }
        );

        this._screens.calcProgressBarPercent(7);

        const otherClaim = this._screens.getOtherClaim();
        if (otherClaim && (otherClaim.price !== 'None')) {
            this.selected = 2;
            this.isDisable = false;
            this.claimInsurerSelected = true;
            this.claimAmountPrice = otherClaim.price;
            this.claimCurrency = otherClaim.currency;

        } else {
            this.claimAmountPrice = null;
            if (otherClaim == null) {
                this.selected = 0;
            } else {
                this.isDisable = false;
                this.selected = 1;
            }
        }
    }

    /**
    * check other insurance is selected or not
    */
    claimInsurer(val: boolean) {
        this.claimInsurerSelected = val;
        if (val === false) { // if no selected
            this.selected = 1;
            this.claimAmountPrice = '';
            const claimPrice = {
            price: 'None',
            currency: '',
        };
        this._screens.setOtherClaimed(claimPrice);
        this._router.navigate(['/claim-process/attach-document']);
        } else {
            this.claimError = false;
            this.claimAmountPrice = null;
            this.selected = 2;
            this.isDisable = true;
        }
    }

    validate(price, event, button) {
        if (price.split('.').length > 1 && (price.split('.').pop().length > 2)) {
            this.claimAmountPrice = price.split('.')[0] + '.' + price.split('.').pop().substr(0, 2);
        } 
        if (event.key == "Enter" && !(this.isDisable)) {
            this.claimPrice(price);
            button.click();
        }
    }
    /**
    * check claimed price and currency from other insurer
    * compare with receipt amount
    * show error message if claim price is greater than reciept amount
    */
    async checkCRange(otherInsurerPrice) {
        if(otherInsurerPrice === '') {
            this.isDisable = true;
            return 0;
        }
        this.isDisable = false;
        let clPrice = parseFloat(this.claimAmountPrice);
        clPrice = await this.convertCurrency(clPrice, this.claimCurrency);
        let oldReceiptPrice = parseFloat(this._screens.getCurrency().price);
        oldReceiptPrice = await this.convertCurrency(oldReceiptPrice, this._screens.getCurrency().currency);
        if (otherInsurerPrice.length <= 0 || otherInsurerPrice.match(/^[0-9]*\.?[0-9]*$/) == null  || otherInsurerPrice.split('.').length > 2 ) {
            this.claimError = true;
            this.isDisable = true;
            this.secondErrorText = this.currencyErrorTexts.wrongAmount;
        } else if (clPrice <= 0 || clPrice >= oldReceiptPrice ) {
            this.claimError = true;
            this.isDisable = true;
            this.secondErrorText = this.currencyErrorTexts.claimAmount;
        } else {
            this.claimError = false;
        }
    }

    onChange(claimamount) {
        if(claimamount){
            this.checkCRange(claimamount)
        }
    }

    /**
    * Converts currency
    */
    async convertCurrency(price: number, currencyCode: string) {
        if (price < 0) {
            return 0;
        }
        if (currencyCode === 'HKD') {
            return price;
        }
        const convertPrice = await this.policyService.convertCurrency(price,currencyCode);
        return convertPrice.amount;
    }


    /**
    * add .00 if not added to price
    */
    claimPrice(otherInsurerPrice) {
        if (otherInsurerPrice.length > 0 && parseFloat(otherInsurerPrice) > 0) {
            const priceStr = this.claimAmountPrice.toString();
            if (!priceStr.includes('.')) {
                this.claimAmountPrice += '.00';
            }
        }
    }

    /**
    * Selected other claim
    */
    selectedOtherClaim() {
        if (this.claimAmountPrice != null) {
            const claimPrice = {
                price: this.claimAmountPrice,
                currency: this.claimCurrency,
            };
            this._screens.setOtherClaimed(claimPrice);
        }
        const latestSCreen = this._screens.getLatestScreen();
        const prevRoute = ['/claim-process', '/claim-process/claim-type','/claim-process/consult-date', '/claim-process/doctor-clinic', '/claim-process/diagnosis', '/claim-process/receipt-amount'];
        if ((latestSCreen != null) && (latestSCreen !== '/claim-process/other-claimed') && (prevRoute.indexOf(latestSCreen) === -1)) {
            this._router.navigateByUrl(latestSCreen);
        } else {
            this._router.navigate(['/claim-process/attach-document']);
        }
    }

    onClose() {
        this.isShowPopup = false;
        this._router.navigate(['/']);
    }
}