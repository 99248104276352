import { Component, OnInit } from '@angular/core';
import { ScreensService } from 'src/app/screens.service';
import { Router } from '@angular/router';
import { PolicyService } from 'src/app/policy.service';


@Component({
  selector: 'app-doctor-clinic',
  templateUrl: './doctor-clinic.component.html',
  styleUrls: ['./doctor-clinic.component.css']
})
export class DoctorClinicComponent implements OnInit {
  searchStr = '';
  isSpeciolFound: boolean;
  errorText: string;
  interval: any;
  isShowPopup = false;
  constructor(private _screens: ScreensService, 
    private _router: Router,
    private policyService: PolicyService  ) { }
  ngOnInit() {
    this.interval = setInterval( () => {
      if(this.policyService.isTokenExpire()) {
        localStorage.removeItem('token');
        this.isShowPopup = true;
        clearInterval(this.interval);
      }
    }, 1000);

    // this.doctorNameSearchArray = JSON.parse(JSON.stringify(this.doctorNameArray));
    this._screens.calcProgressBarPercent(4);
    this.searchStr = this._screens.getDoctorName();
    if (this.searchStr == null) {
      this.searchStr = '';
    }
  }

  validate(event, button) {
    if (this.searchStr.trim() == '') {
      this.searchStr = '';
    }
    if(this.searchStr.length > 100) {
      this.isSpeciolFound = true;
      this.errorText = 'Name of Doctor or Clinic can contain max 100 characters.';
    } else {
      this.isSpeciolFound = false;
      if (event.key == "Enter") {
        button.click();
      }
    }
  }


  /**
   * Selected doctor
   * @param doctorName stor to localstorage
   */
  selectedDoctor(doctorName: string) {
    this._screens.setDoctor(doctorName);
    const latestSCreen = this._screens.getLatestScreen();
    const prevRoute = ['/claim-process', '/claim-process/claim-type', '/claim-process/consult-date'];
    if ((latestSCreen != null) && (latestSCreen !== '/claim-process/doctor-clinic') && (prevRoute.indexOf(latestSCreen) === -1)) {
      this._router.navigateByUrl(latestSCreen);
    } else {
      const claim = this._screens.getClaim();
      if(claim != null){
        if(claim.diagnosisTypes.length === 1){
          this._screens.setDiagnosis(claim.diagnosisTypes[0]);
          this._router.navigate(['/claim-process/receipt-amount']);
        } else if(claim.diagnosisTypes.length ===0){
          const json = {
            description: 'No Diagnosis',
            code: '999',
            diagnosisChineseDescription: 'no-diagnosis'
          };
          this._screens.setDiagnosis(json);
          this._router.navigate(['/claim-process/receipt-amount']);
        } else {
          this._router.navigate(['/claim-process/diagnosis']);
        }
      }
    }
  }
  onClose(){
    this.isShowPopup = false;
    this._router.navigate(['/']);
  }
}
