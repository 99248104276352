import { Component, OnInit, OnDestroy } from '@angular/core';
import { Claim } from 'src/app/models/models';
import { PolicyService } from 'src/app/policy.service';
import { ScreensService } from 'src/app/screens.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { constants } from 'src/app/constants';

@Component({
  selector: 'app-claim-type',
  templateUrl: './claim-type.component.html',
  styleUrls: ['./claim-type.component.css']
})
export class ClaimTypeComponent implements OnInit,OnDestroy {
  memberTypeArray: Claim[];
  memberTy: Claim;
  errorText: string;
  interval: any;
  isShowPopup = false;
  loading = false;
  constructor(
    private policyService: PolicyService,
    private screensService: ScreensService,
    private _router: Router) { }

  ngOnInit() {
    this.screensService.calcProgressBarPercent(2);
    let members = this.policyService.getInsuredMembers();
    let memberTypeArray = JSON.parse(localStorage.getItem('claims'));
    
    if ((members.length === 1 && memberTypeArray == null) || memberTypeArray == null) {
        this.loading = true;
        this.policyService.getClaimDetails().subscribe(
          (res: any) => {
              let lang = this.screensService.getLanguage();
              if (lang == null || lang == "null") {
                lang = 'en';
              }
              this.policyService.setApiResponse(lang + '-claims', res.result);
              const claims = Object.assign([], res.result);
              localStorage.setItem('claims', JSON.stringify(claims));
              this.loading = false;
              this.memberTypeArray = res.result;
          },
          (err) => {
            // console.log(err);
            if (!navigator.onLine) {
              this.errorText = constants.networkError;
            } else {
              this.errorText = constants.serverError;
            }
            this.loading = false;
          }
        );

    } else {
      memberTypeArray.sort( (a,b) => a.orderSequence - b.orderSequence);
    }


    this.memberTypeArray = memberTypeArray;
    this.policyService.memberTypeArray.subscribe(
      (res: any) => {
        this.memberTypeArray = res;
        this.memberTypeArray.sort( (a,b) => a.orderSequence - b.orderSequence);
      },
      (err) => {
        // console.log(err);
      }
    );
    this.memberTy = this.screensService.getClaim();
    this.interval = setInterval( () => {
      if( this.policyService.isTokenExpire()) {
        localStorage.removeItem('token');
        this.isShowPopup = true;
        clearInterval(this.interval);
      }
    }, 1000)
  }

  /**
   * Selectes claim type
   * @param member store to localstorage
   */
  selecteClaimType(member: Claim) {
    this.screensService.setClaimType(member);
    const dNames = {receipt: '', referral: ''};
    for (const d of member.docTypes) {
      if (d.docCode.toLowerCase() === 'receipt') {
        dNames.receipt = d.docName;
      } else {
        dNames.referral = d.docName;        
      }
    }
    this.screensService.setDocNames(dNames);
    // if (this.memberTy && (member.description !=  this.memberTy.description) && this.screensService.getDiagnosis() != null) {
    //   this.screensService.setDiagnosisError(true);
    // } else {
    //   this.screensService.setDiagnosisError(false);
    // }
    // if (this.memberTy && (member.description != this.memberTy.description) && this.screensService.getDiagnosis() != null) {
if (this.screensService.getDiagnosis() != null) {
  // for checking two claim types that their diagnosis types are same then do not show diagnosisError
  let sameType = member.diagnosisTypes.find((elem)=>{
  return elem.code == this.screensService.getDiagnosis().code;
  })
  
  if (!sameType) {
  this.screensService.setDiagnosisError(true); 
  } else {
  this.screensService.setDiagnosisError(false);
  }
  }

    if (this.screensService.getFiles() != null && member.description !=  this.memberTy.description) {
      const prevFileObj = this.screensService.getFiles();
      const obj = {};
      member.docTypes.forEach(value => {
        if (prevFileObj[value.docCode] != undefined) {
          obj[value.docCode] = prevFileObj[value.docCode];
        } else {
          obj[value.docCode] = [];
        }
      });
      this.screensService.saveFiles(obj);
    }
    let latestSCreen = this.screensService.getLatestScreen();
    const prevRoute = ['/claim-process'];
    if ((latestSCreen != null) && (latestSCreen !== '/claim-process/claim-type') && prevRoute.indexOf(latestSCreen) === -1) {
      if(latestSCreen.indexOf('attach-documnet') != -1) {
        latestSCreen = '/claim-process/attach-document';
      }
      this._router.navigate([latestSCreen]);
    } else {
      this._router.navigate(['/claim-process/consult-date']);
    }
  }
  onClose(){
    this.isShowPopup = false;
    this._router.navigate(['/']);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
