import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-open-app',
  templateUrl: './open-app.component.html',
  styleUrls: ['./open-app.component.css']
})
export class OpenAppComponent implements OnInit {

  constructor() { }
  urls = environment
  ngOnInit() {
    if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {
      window.location.href = this.urls.appAppleUrl;
    } else if (navigator.userAgent.match(/Android/i)) {
      window.location.href = this.urls.appPlayStoreUrl;
    }
  }

}
