import { Component, OnInit } from '@angular/core';
import { ScreensService } from 'src/app/screens.service';
import { Router } from '@angular/router';
import { PolicyService } from 'src/app/policy.service';
import { Currency } from 'src/app/models/models';

@Component({
  selector: 'app-reciept-amount',
  templateUrl: './reciept-amount.component.html',
  styleUrls: ['./reciept-amount.component.css']
})
export class RecieptAmountComponent implements OnInit {

  currency: string;
  price: string;
  showError                       = false;
  errorText: string;
  currencyErrorTexts = {
    wrongAmount: 'Please enter the correct amount.',
    errorTextAmount: 'Amount should be less than 9,999,999 and greater than 0.',
    claimAmount: 'Amount should be greater than 0 and less than claim amount.',
    decimalAmount: 'Amount should be less than 7 digits before decimal and 2 digits after decimal.'
  };
  otherClaim: any;

  constructor(private policyService: PolicyService,
     private _screens: ScreensService, 
     private _router: Router) { }
  currencies: Currency[];
  interval: any;
  isShowPopup = false;
  ngOnInit() {
    this.interval = setInterval( () => {
      if(this.policyService.isTokenExpire()) {
        localStorage.removeItem('token');
        this.isShowPopup = true;
        clearInterval(this.interval);
      }
    }, 1000);
    this.currency               = 'HKD';
    this._screens.calcProgressBarPercent(6);
    const amount = this._screens.getCurrency();
    if (amount) {
      this.price  = amount.price;
      this.currency = amount.currency;
    } else {
      this.price = null;
    }
    this.otherClaim = this._screens.getOtherClaim();
    this.policyService.getCorruncyList().subscribe(
      (resp: Currency[]) => {
        this.currencies = resp;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  validate(price, event, button) {
    if (price.split('.').length > 1 && (price.split('.').pop().length > 2)) {
      this.price = price.split('.')[0] + '.' + price.split('.').pop().substr(0, 2);
    } 
    if (event.key == "Enter" && this.price != null && this.price != '' && !(this.showError)) {
      this.addPrice(price);
      button.click();
    }
  }

  /**
   * check the amount is less than other claim amount
   * check tha amount is greater than 0 and less than 9999999
   */
  async checkRange(price) {
    const range = parseFloat(this.price);
    if (this.otherClaim != null && this.otherClaim.price != 'None') {
      const otherClaimCurrency = Math.floor(await this.convertCurrency(this.otherClaim.price, this.otherClaim.currency));
      const reciptAmount = await this.convertCurrency(range, this.currency);
      if (reciptAmount <= otherClaimCurrency ) {
          this.showError = true;
          this.errorText = 'Amount should be greater than other claim amount.';
          return 0;
      }
    }
    if (price.length <= 0 || price.match(/^[0-9]*\.?[0-9]*$/) == null  || price.split('.').length > 2 ) {
      // this.price = '';
      this.showError = true;
      this.errorText = this.currencyErrorTexts.wrongAmount;
    } else if (range <= 0 || range >= 9999999) {
      // this.price = '';
      this.showError = true;
      this.errorText = this.currencyErrorTexts.errorTextAmount;
    }else {
      this.showError = false;
    }
  }
  
  /**
   * Determines whether change on currency
   */
  onChange() {
    if (this.price) {
      this.checkRange(this.price);
    }
  }

  /**
   * Converts currency
   */
  async convertCurrency(price: number, currencyCode: string) {
    if (price < 0) {
      return 0;
    }
    if (currencyCode === 'HKD') {
      return price;
    }
    const convert = await this.policyService.convertCurrency(price, currencyCode);
    return convert.amount;
  }


    /**
   * Add Decimal digit to Price Value
   * @param price string
   */
  addPrice(price: string) {
    if (price.length > 0 && parseFloat(price) > 0) {
      // this.twoDots = false;
        if (!price.includes('.')) {
          price += '.00';
          this.price = price;
          this.checkRange(this.price);
        } else {
          this.price = price;
          this.checkRange(this.price);
        }
    } //else {
          //this.twoDots = true;
        //}
  }

  /**
   * Selected currency
   */
  selectedCurrency() {
    const json = {
      price: this.price,
      currency: this.currency,
    };
    this._screens.setCurrency(json);
    const latestSCreen = this._screens.getLatestScreen();
    const prevRoute = ['/claim-process','/claim-process/claim-type','/claim-process/consult-date','/claim-process/doctor-clinic','/claim-process/diagnosis'];
    if ((latestSCreen != null) && (latestSCreen !== '/claim-process/receipt-amount') && (prevRoute.indexOf(latestSCreen) === -1)) {
      this._router.navigateByUrl(latestSCreen);
    } else {
      this._router.navigate(['/claim-process/other-claimed']);
    }
  }
  onClose() {
    this.isShowPopup = false;
    this._router.navigate(['/']);
  }
}
