/**
* Author: ShepHertz
* Interfaces and classes for sliderform, Diagnosis, Doctor
*/
export interface IInsuredMember {
    schemecode: string;
    polno: string;
    certNo: string;
    effDate: string;
    isTerminated: string;
    customerHotline: string;
    inceptionDate: string;
    emergencyHotline: string;
    panelcode: string;
    cardColor: string;
    mmrlnm: string;
    subsidiary: string;
}

export interface IPolicyInput {
    wsVer: string;
    policyNumber: string;
    certNo: string;
    language: string;
    identityNumber: string;
    dob: string;
}

export class PolicyInput implements IPolicyInput{
    wsVer: string;
    policyNumber: string;
    certNo: string;
    language: string;
    identityNumber: string;
    dob: string;
    constructor(policyNumber: string, certNo: string, identityNumber: string, lang: string) {
        if (lang == null || lang == 'null') {
            lang = 'EN';
        }
        this.wsVer = '1.0';
        this.policyNumber = policyNumber;
        this.certNo = certNo;
        this.language = lang;
        this.identityNumber = identityNumber;
        this.dob = '1988-01-01';
    }
}

export interface Policy {
    agreeMrkt: string;
    policyDetail: {
        accessLevel: string;
        brokerAgentId: number;
        policyNo: string;
        panelCode: string;
        effFrom: string;
        productName: string;
        claimSubmissionEnabled: string;
        certNo: string;
        isDefault: string;
        brokerCode: string;
        benfitEnabled: string;
        noOfDep: string;
        members: IInsuredMember[]
        brokerName: string;
        policyHolder: number;
        effTo: string
    };
    name: string;
    message: string;
    status: number;
}

export interface Claim {
    type: string;
    description: string;
    claimTypechineseDescription: string;
    orderSequence: number;
    docTypes: any[];
    diagnosisTypes: any[];
    claimType: string;
    claimTypeDescription: string;
}

export interface ClaimDetail {
    result: Claim[];
    status: number;
}

export interface IDoctor {
    id: number;
    name: string;
}

export class Doctor implements IDoctor {
    id: number;
    name: string;
    constructor() {
    }
}

export interface IDiagnosis {
    id: number;
    name: string;
}

export class Diagnosis implements IDiagnosis {
    id: number;
    name: string;
    constructor() {
    }
}
export interface TokenResponse{
    status: boolean;
    message: string;
    content: {
        token: string,
        expires:number,
        refreshToken:string
    };
}
export const headerData = {
    appId : 'WebClaim',
    appVer : '1.0',
    platform : 'Browser',
}
export interface Currency {
    currencyCode: string;
    currencyDescription: string;
}

// export interface ClaimSubmitRequest{
//     numberOfPhoto: number;
//     submissionType: String;
//     claimCategoryCode: string;
//     policyNumber: string;
//     policySubCode: string;
//     certificateNumber: string;
//     claimType: string;
//     patientName: string;
//     consulationDate: string;
//     receiptCurrency: string;
//     receiptAmount: string
//     otherPaidCurrency: string
//     otherPaidAmount: string
//     doctorName: string;
//     diagnosisCode: string;
//     willSubmitOtherInsurer: string;
//     transactionStatus: string;
//     otherDiagnosis: string;
//     docId: string;
// }